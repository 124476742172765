import '../i18n';

import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import StoreRestaurantRegistrationBody from '../components/StoreRestaurantRegistrationBody';

const StoreRestaurantRegistration = () => (
  <React.Fragment>
    <title>Store/Restaurant Registration</title>
    <NavbarWhite />
    <StoreRestaurantRegistrationBody />
    <Footer />
  </React.Fragment>
);

export default StoreRestaurantRegistration;
