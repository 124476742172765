import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';

import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import React, { Component, useState } from 'react';

import ImgShapyaIconFluidSq from './imgComponents/imgShapyaIconFluidSq';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Link } from '@reach/router';
import ShapyaSpinner from '../images/shapya_spinner.gif';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const StoreRestaurantRegistrationBodyForm = () => {
  const { t } = useTranslation();
  const onChangeLanguage = () => {};

  const [username, setShapyaUsernameRegistration] = useState('');
  const [email, setShapyaEmailRegistration] = useState('');
  const [password1, setShapyaPassword1Registration] = useState('');
  const [password2, setShapyaPassword2Registration] = useState('');

  const register = event => {
    fetch(process.env.API_URL + '/auth/registration/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, email, password1, password2 }),
    })
      .then(data => data.json())
      .catch(error => console.error(error));
  };

  return (
    <div>
      <h1
        className="shapyaMediumRed"
        style={{ fontSize: 'calc(26px + 6 * ((100vw - 320px) / 680))' }}
      >
        <b>{t('registration.storerestaurant.title')}</b>
      </h1>
      <br />
      <br />
      <form>
        <Grid container spacing={4}>
          <Grid item className="migrid" xs={12} align="center">
            <Box
              style={{
                maxWidth: '100px',
              }}
            >
              <ImgShapyaIconFluidSq className="webpimg" />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="registration_storerestaurant_username"
                fullWidth
                autoComplete="none"
                required
                label={t('registration.storerestaurant.labelusername')}
                placeholder={t(
                  'registration.storerestaurant.placeholderusername',
                )}
                inputProps={{
                  maxLength: 30,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={username}
                onChange={event =>
                  setShapyaUsernameRegistration(event.target.value)
                }
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="registration_storerestaurant_email"
                fullWidth
                autoComplete="none"
                required
                label={t('registration.storerestaurant.labelemail')}
                placeholder={t('registration.storerestaurant.placeholderemail')}
                inputProps={{
                  maxLength: 30,
                  pattern:
                    '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={email}
                onChange={event =>
                  setShapyaEmailRegistration(event.target.value)
                }
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="registration_storerestaurant_password1"
                fullWidth
                type="password"
                autoComplete="none"
                required
                label={t('registration.storerestaurant.labelpassword1')}
                placeholder={t(
                  'registration.storerestaurant.placeholderpassword1',
                )}
                inputProps={{ minLength: 8, maxLength: 16 }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={password1}
                onChange={event =>
                  setShapyaPassword1Registration(event.target.value)
                }
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="registration_storerestaurant_password2"
                fullWidth
                type="password"
                autoComplete="none"
                required
                label={t('registration.storerestaurant.labelpassword2')}
                placeholder={t(
                  'registration.storerestaurant.placeholderpassword2',
                )}
                inputProps={{ maxLength: 16 }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={password2}
                onChange={event =>
                  setShapyaPassword2Registration(event.target.value)
                }
              />
            </Box>
          </Grid>

          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox">
              <br />
              <br />
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                className="formbutton"
                id="sending_button"
                style={{
                  textTransform: 'none',
                  fontFamily: 'Montserrat',
                  padding: '20px',
                  backgroundColor: '#b73a40',
                  marginBottom: '0px',
                  width: '228px',
                  fontSize: '40px',
                }}
                onClick={register}
              >
                <h3>{t('registration.button')}</h3>
              </Button>
              <Box mt={2}>
                <Link to="/b2b-login" className="shapyaText shapyaMediumRed">
                  <h4>{t('login.userlogin')}</h4>
                </Link>
              </Box>
              <Link to="/thanksMessage" id="linktm"></Link>
              <p style={{ display: 'none' }} id="formloader">
                <img src={ShapyaSpinner} width="120" />
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default StoreRestaurantRegistrationBodyForm;
