import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';
import './styles/shapyaMaxWidth.css';

import { Grid } from '@material-ui/core';
import React from 'react';
import StoreRestaurantRegistrationBodyForm from './StoreRestaurantRegistrationBodyForm';

const StoreRestaurantRegistrationBody = () => {
  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        direction="row"
        justify="center"
        className="shapyerFormMainGrid"
      >
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          className="shapyerFormRightGrid"
          align="center"
        >
          <StoreRestaurantRegistrationBodyForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default StoreRestaurantRegistrationBody;
